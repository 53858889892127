<template>
  <div>
    <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>教务中心</a-breadcrumb-item>
        <a-breadcrumb-item>我的试听</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
        <div class="clearfix table-tools">
        <div class="buttons">
            <a-form layout='inline'>
            <a-form-item>
                <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
            </a-form-item>
            </a-form>
        </div>
        <div class="search">
            <a-form layout='inline' @submit="searchList">
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <a-select :value='searchData.is_sub' @change='handleIsSub' style="width: 100px">
                    <a-select-option :value="0">我的</a-select-option>
                    <a-select-option :value="1">我下属的</a-select-option>
                </a-select>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>学员姓名/手机号</span>
                </template>
                <a-input allowClear v-model.trim="searchData.fuzzy" placeholder="学员姓名/手机号" style="width: 160px"/>
                </a-tooltip>
            </a-form-item>

            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>上课校区</span>
                </template>
                  <a-select style="width:160px" allowClear v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择上课校区" showSearch :filterOption="filterOption">
                      <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                  </a-select>
                </a-tooltip>
            </a-form-item>

            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>上课日期</span>
                </template>
                  <a-range-picker style="width:240px" :ranges="rangesData" allowClear v-model="searchData.class_date" @canplay="handleTime" :placeholder="['开始时间', '结束时间']"/>
                </a-tooltip>
            </a-form-item>

            <a-form-item>
              <a-radio-group :value="searchData.book_status">
                <a-radio-button @click="handleTypeButChange('1')" value="1">
                    待上课
                </a-radio-button>
                <a-radio-button @click="handleTypeButChange('2')" value="2">
                    已上课
                </a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item>
                <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
                <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                    <div class="more-search">
                    <a-form layout='inline'>
                        <a-form-item class="block-line" label="课程名称" >
                        <a-select allowClear v-model="searchData.course_id" placeholder="请选择">
                        <a-select-option v-for="(d, index) of courses" :key="index" :value="d['course_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-form-item>
                        <a-form-item class="block-line" label="授课老师" >
                        <a-select allowClear showSearch v-model="searchData.teacher_id" placeholder="请选择" :filterOption="filterOption" >
                            <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                        </a-select>
                        </a-form-item>
                        <!-- <a-form-item class="block-line" label="课程模式" >
                        <a-select allowClear v-model="searchData.class_mode" placeholder="请选择">
                            <a-select-option v-for="(d, index) of classModes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                        </a-select>
                        </a-form-item> -->
                        <a-form-item class="block-line" label="排课员工">
                            <a-select allowClear showSearch v-model="searchData.created_by" placeholder="请选择" :filterOption="filterOption" >
                                <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item class="block-line" label="排课日期">
                            <a-range-picker :ranges="rangesData" allowClear v-model="searchData.arrange" @canplay="handleTime" :placeholder="['开始时间', '结束时间']"/>
                        </a-form-item>
                    </a-form>
                    <div class="more-search__buttons">
                        <a-button type="dashed" @click="closeSearch">取消</a-button>
                        <a-button @click="reset">重置</a-button>
                        <a-button type="primary" @click="searchList">搜索</a-button>
                    </div>
                    </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
                </a-popover>
            </a-form-item>
            </a-form>
         </div>
        </div>
        <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="book_id"
            :columns="columns" :dataSource="list" :scroll="{ x: 1600 }" @change="handleChange">

            <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>

            <template slot="student_name" slot-scope="text, record">
                <div style="display:flex;align-items: center;">
                  <img v-if="record.is_demo" style="width:18px;margin-right: 5px;" src="@/assets/demo.png">  
                  <!-- <a href="javascript:;" @click="showMessage(record)"></a> -->
                  <span>{{text}}</span>
                </div>
            </template>

            <template slot="images" slot-scope="text, record">
            <LImg :preview="0" v-for="(image, index) of record.cancel_image" :key="index"  
                :src="image" style="width: 44px; height: 44px; margin: 0 5px 5px 0"/>
            </template>

            <template slot="classDate" slot-scope="text, record, index">
              <a @click="showStudentsModal(record)" v-if="record.class_start">
                <div style="margin-right:5px;line-height:19px">{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</div>
              </a>
            </template>

            <template slot="classes_name" slot-scope="class_name, record">
              <a-tooltip>
                <template slot="title">
                  {{ class_name }}
                </template>
                <a @click="showDetailModal(record,'1')">{{ class_name }}</a>
              </a-tooltip>
            </template>

            <template slot="status" slot-scope="text">
                <span v-if="text == 0">已请假</span>
                <span v-if="text == 1">待上课</span>
                <span v-if="text == 2">已上课</span>
            </template>

            <template slot="action" slot-scope="text,record,index"></template>
        </a-table>
        </div>
        <div class="page">
        <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
            </template>
            </a-pagination>
        </div>
    </div>
    <detailModal v-if="detailVisible" :item="modalData"/>
    <studentsModal v-if="studentsVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '学员名称', dataIndex: 'student_name', key: '6' , width:'140px',ellipsis:true , scopedSlots: { customRender: 'student_name' }},
  { title: '上课时间', dataIndex: 'class_start', width:'250px', key: 'class_start', scopedSlots: { customRender: 'classDate' } ,sorter:()=>{}},
  { title: '班级名称', dataIndex: 'classes_name', key: '1' ,scopedSlots: { customRender: 'classes_name' },ellipsis:true},
  { title: '上课校区', width: '120px', dataIndex: 'studio_id', key: 'name', ellipsis:true,sorter:()=>{}},
  { title: '授课老师', dataIndex: 'teacher_name',width: '80px', key: '3' ,ellipsis:true,sorter:()=>{}},
  { title: '助教老师', dataIndex: 'teacher_assistant', key: 'teacher_assistant' ,ellipsis:true},
  { title: '课程名称', dataIndex: 'course_name',width: '80px', key: '2' ,ellipsis:true},
  { title: '上课教室', dataIndex: 'room_id',width: '80px', key: 'room_id' ,ellipsis:true},    
  { title: '扣课', dataIndex: 'book_costs',width: '80px', key: 'book_costs' ,ellipsis:true,align:"right"},
  { title: '课消', dataIndex: 'book_balance',width: '80px', key: 'book_balance' ,ellipsis:true,align:"right"},
  { title: '签到时间', dataIndex: 'checked_at', key: 'checked_at' ,ellipsis:true,align:"center"},
  { title: '签到员工', dataIndex: 'checked_by', key: 'checked_by' ,ellipsis:true,align:"center"},
  { title: '状态', dataIndex: 'book_status', width: 80, key: '13', scopedSlots: { customRender: 'status' },align:'center',sorter:()=>{}},
  { title: '来源渠道', dataIndex: 'book_channel_name' , ellipsis:true, width: 100, key: 'book_channel_name', align:'center'},
  { title: '排课员工', dataIndex: 'created_by', ellipsis:true, width: 80, key: 'created_by', align:'right'},
  { title: '排课时间', dataIndex: 'created_at', key: 'created_at',ellipsis:true, align:'right',sorter:()=>{}},
  
//   { title: '操作', key: 'operation', fixed: 'right', width: 90, scopedSlots: { customRender: 'action' },align:'right'}
]
    import C_ITEMS from '@/utils/items'
    import ranges from "@/common/mixins/ranges"
    import moment from 'moment'
    import tableMixins from '@/common/mixins/table'
    import detailModal from '@/views/classes/bjgl/detail/index'
    import studentsModal from '@/views/classes/bjgl/detail/bjpk/studentModal'
    export default {
        name:"audition",
        data() {
            return {
                columns,
                loading: false,
                list:[],
                detailVisible: false,
                exportLoading: false,
                studentsVisible: false,
                searchData:{},
                classModes: C_ITEMS.classModes,
                studios: [],
                courses: [],
                teachers: [],
                searchData: {
                    is_sub:0,
                    class_date:[moment(),moment().add(1, 'week')]
                },
                visible: false
            }
        },
        components: {
            detailModal,
            studentsModal,
        },
        mixins: [ ranges, tableMixins ],
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created() {
            this.getStudio()
            this.getCourses()
            this.getTeacher()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                let obj = {}
                let { searchData } = this
                if (searchData) {
                    for (let k in searchData) {
                    if (k === 'class_date') {
                        obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
                        obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
                    } else if(k === 'arrange'){
                        obj['arrange_start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
                        obj['arrange_end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
                    } else {
                        obj[k] = searchData[k]
                    }
                    }
                }
                this.searchParams.search = obj
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                this.searchData.is_sub = this.$ls.get('is_sub')
                await this.$store.dispatch('scheduleAuditionAction', this.searchParams)
                .then(res=>{
                    this.list = res.items
                    this.list.push({
                        book_id:-1,
                        student_name:'',
                        class_start:'',
                        studio_id:'',
                        classes_name:'',
                        teacher_name:'',
                        course_name:'',
                        book_costs:res._total.book_costs,
                        book_balance:res._total.book_balance,
                        book_status:-1,
                    })
                    this.pageParams = res._meta
                    this.loading = false
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            closeSearch() {
                this.visible = false
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            async getCourses() {
                let res = await this.$store.dispatch('searchCourseAction', {})
                this.courses = res.data
            },
            async getTeacher() {
                let res = await this.$store.dispatch('searchTeacherAction', {})
                this.teachers = res.data
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList()
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchData.is_sub = e
                this.$forceUpdate()
            },
            handleTime(){
                this.$forceUpdate()
            },
            showStudentsModal(item) {
                this.modalData = item
                this.studentsVisible = true
            },
            hideStudentModal() {
                this.studentsVisible = false
            },
            showDetailModal(item,num) {
                this.modalData = item
                this.modalData.activeIndex = num
                this.detailVisible = true
            },
            hideDetailModal() {
                this.detailVisible = false
            },
            handleTypeButChange(e){
                if(this.searchData.book_status == e){
                    this.searchData.book_status = undefined
                }else{
                    this.searchData.book_status = e
                }
                this.getList()
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    book_status:2,
                    search:this.searchParams.search
                }
                let res = await this.$store.dispatch('scheduleAuditionExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `试听.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>